import { StaticImage } from 'gatsby-plugin-image';
import { ExternalLink } from 'components/Links';

export const useEventsContent = () => [
  {
    header: 'Sharesight Investor-tech Conference 2023 risk panel discussion',
    textContent: (
      <p>
        The risk panel discussion covers the topic of how investors should think about their
        portfolio risk and how they can prepare for it.
        <br /> <em>Panel discussion from 23 August 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=bF8nZ0GLQ8o"
        title="Sharesight Investor-tech Conference 2023 risk panel discussion"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/investor-tech.png"
        alt="Sharesight Investor-tech Conference 2023 risk panel discussion"
      />
    ),
  },
  {
    header: 'Key insights from the 2023 ASX Investor study',
    textContent: (
      <p>
        Join Rory Cunningham, Senior Manager of Investment Products from the ASX, on the latest
        trends in Australian investments.
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=-2DxXbygpqY"
        title="Key insights from the 2023 ASX Investor study event"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/2023-asx-investor-study.png"
        alt="Key insights from the ASX Investor Study banner"
      />
    ),
  },
  {
    header: 'How to use Sharesight at tax time in NZ',
    textContent: (
      <p>
        Our Head of Product, Ben Clendon, covers several topics in this video that are relevant to
        NZ investors. One of the essential topics he discusses is how to generate tax reports,
        specifically the FIF report, for tax purposes. <br />
        <em>Webinar recording from 28 June 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=7wKyrNxyb20"
        title="Event recording: How to use Sharesight at tax time in NZ"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/tax-time.png"
        alt="Using Sharesight at tax time banner"
      />
    ),
  },
  {
    header: 'Tax planning for Australian investors',
    textContent: (
      <p>
        Umair Minhas, Direct of Tax at PwC, takes us through various tax planning strategies and
        answers a lot of questions. <br />
        <em>Webinar recording from 07 June 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=efap9eaavmE"
        title="Event recording: Tax planning for Australian Investors"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/tax-planning.png"
        alt="Tax planning for Australian investors banner"
      />
    ),
  },
  {
    header: 'Tracking employee stock options on Sharesight',
    textContent: (
      <p>
        Doug Morris takes us through how best to keep track of various flavours of stock options on
        Sharesight. <br />
        <em>Webinar recording from 24 May 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=uGgWez28qnU"
        title="Event recording: Tracking employee stock options on Sharesight"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/stock-options.png"
        alt="Tracking employee stock options on Sharesight banner"
      />
    ),
  },

  {
    header: 'Adviser Innovation Summit 2021',
    textContent: (
      <p>
        Sharesight’s Chris Stojcevski uncovers how Saas can improve your insights and reduce costs
        for your clients. <br />
        <em>Event recording from 9 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=whZJiqO1BQc"
        title="Event recording: Adviser Innovation Summit 2021"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/ais-2021.png"
        alt="Adviser Innovation Summit 2021"
      />
    ),
  },
  {
    header: 'Harnessing technology to improve portfolio performance',
    textContent: (
      <p>
        These panel of experts answer challenging questions from the audience when it comes to using
        technology for DIY investing. <br />
        <em>Event recording from 10 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=nS7rrZJYTE8"
        title="Event recording: Harnessing technology to improve portfolio performance"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/harnessing-tech.png"
        alt="Harnessing technology to improve portfolio performance"
      />
    ),
  },
  {
    header: 'DIY investing all or nothing',
    textContent: (
      <p>
        These panel of experts discuss navigating the options of fully automated, in person or
        hybrid investing approaches.
        <br />
        <em>Event recording from 10 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=7MU_Nnfm65s"
        title="Event recording: DIY investing all or nothing"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/diy-investing.png"
        alt="DIY investing all or nothing"
      />
    ),
  },
  {
    header: 'What is the ultimate SMSF tech stack?',
    textContent: (
      <p>
        These panel of experts discuss the ultimate SMSF tech stack: How to unbundle your SMSF to
        potentially save thousands of dollars. <br />
        <em>Event recording from 10 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=_crPGVsvw2I"
        title="Event recording: What is the ultimate SMSF tech stack?"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/smsf-tech-stack.png"
        alt="What is the ultimate SMSF tech stack?"
      />
    ),
  },
  {
    header: 'How to effectively buy and sell',
    textContent: (
      <p>
        Find out how to efficiently buy and sell, while avoiding costly currency pitfalls with the
        influx of international share trading. <br />
        <em>Event recording from 10 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=VJiODPe14nw"
        title="Event recording: How to effectively buy and sell"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/buy-and-sell.png"
        alt="How to effectively buy and sell"
      />
    ),
  },
  {
    header: 'Tools to help make the best investment decisions',
    textContent: (
      <p>
        These panel of experts discuss how investors can get a better understanding of tools
        available as an appropriate solution to consider. <br />
        <em>Event recording from 10 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=QjOT590LrOY"
        title="Event recording: Tools to help make the best investment decisions"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/best-decisions.png"
        alt="Tools to help make the best investment decisions"
      />
    ),
  },
  {
    header: 'Sharesight on The IFA Show',
    textContent: (
      <p>
        Sharesight Pro Account Manager, Chris Stojcevski discusses why financial advisers are moving
        away from platforms to reduce costs for their clients. <br />
        <em>Podcast recording from 07 July 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.ifa.com.au/podcast/29775-financial-advisers-are-moving-away-from-platforms-to-reduce-costs-for-their-clients"
        title="Podcast recording: Sharesight on The IFA Show"
        asButton
        appearance="secondary"
      >
        Listen
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/ifa-show.png"
        alt="Sharesight on The IFA Show"
      />
    ),
  },
  {
    header: 'Sharesight on Marcus Today',
    textContent: (
      <p>
        Sharesight CEO Doug Morris sits down with Henry Jennings from Marcus Today to talk Bitcoin,
        NFTs and the rise of fintechs. <br />
        <em>Podcast recording from 23 April 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.buzzsprout.com/967801/8388108"
        title="Podcast recording: Sharesight on Marcus Today"
        asButton
        appearance="secondary"
      >
        Listen
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/marcus-today.png"
        alt="Sharesight on Marcus Today"
      />
    ),
  },
  {
    header: 'Disruption in a changing world',
    textContent: (
      <p>
        Sharesight CEO, Doug Morris joins Equity ASA and talks about what disruption really means,
        who it benefits and how it’s changing the world.
        <br />
        <em>Podcast recording from 28 April 2020.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://pca.st/b9ix04lp"
        title="Podcast recording: Equity ASA"
        asButton
        appearance="secondary"
      >
        Listen
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/disruption-changing-world.png"
        alt="Disruption in a Changing World"
      />
    ),
  },
];

import { useMemo } from 'react';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { HeroContainer, Section, SectionImage } from 'components/Container';

import { useEventsContent } from 'page-data/events';
import { ContentBlock } from 'components/ContentBlock';

import HeroImage from 'images/events/hero.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const Events: FC<LocalizedPageProps> = () => {
  const events = useEventsContent();

  const eventBlocks = useMemo(
    () =>
      events.map(({ header, textContent, button, image }) => (
        <ContentBlock
          key={header}
          header={header}
          textContent={textContent}
          button={button}
          image={image}
        />
      )),
    [events]
  );

  return (
    <Layout>
      <Seo />

      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>
            Events, Webinars and <br />
            Podcasts
          </h1>
          <p>
            Join Sharesight team members and partners for deep-dives into our award-winning
            portfolio tracker, new feature launches, investor education, and more.
          </p>
        </Section>

        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>

      {eventBlocks}
    </Layout>
  );
};

export default Events;
